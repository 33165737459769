import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faDoorClosed, faDoorOpen, faCircleCheck, faTriangleExclamation, faRotate, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

export const API_V1 = 'api/v1';
export const BASE_URL = 'https://www.yekkada.com/';
// export const BASE_URL = 'http://127.0.0.1:8095/';
export const GMAP_LOCATION_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json?'
export const LOGGED_IN_USER = 'loggedInUser'
export const IS_AUTHENTICATED = 'isAuthenticated'
export const EP_ASSETS_GET_ALL = 'assets/getAllAssets'
export const LOGIN = 'login'
export const NO_INFO = 'NA'
export const OFFLINE_TIMEOUT = 18000
export const COMP_DASHBOARD = 'Dashboard'
export const BEARER = 'Bearer '
export const LOCK_CLOSE = <><FontAwesomeIcon style={{ color: '#363166' }} size="xl" icon={faLock} fade /> Inserted</>
export const LOCK_OPEN = <><FontAwesomeIcon style={{ color: '#ff683a' }} size="xl" icon={faLockOpen} shake /> Unplugged</>
export const BACK_COVER_CLOSE = <><FontAwesomeIcon style={{ color: '#363166' }} size="xl" icon={faDoorClosed} fade /> Closed</>
export const BACK_COVER_OPEN = <><FontAwesomeIcon style={{ color: '#ff683a' }} size="xl" icon={faDoorOpen} shake /> Open</>
export const NORMAL = <><FontAwesomeIcon icon={faCircleCheck} size="xl" style={{ color: "#363166", }} /> No</>
export const ALERT_WARNING = <><FontAwesomeIcon icon={faTriangleExclamation} size="xl" fade style={{ color: "#FF683A", }} /> Yes</>
export const LOADING_ARROW = <FontAwesomeIcon icon={faRotate} spin size="lg" style={{ color: "#5e77a1", }} />
export const DEVICE_OFFLINE = <span style={{ color: "GrayText", textDecoration: "line-through" }}>Offline</span>
export const DEVICE_ONLINE = <span style={{ color: "green" }}><b>Online</b></span>
export const GPS_WEBSOCKET = 'gps-websocket'
export const FAIL = "Fail";
export const SUCCESS = "Success";
export const LOCKED = 'Locked'
export const OPEN = 'Open'
export const CLIENT_ADMIN = "CLIENT_ADMIN"
export const SELECTED = 'selected'
export const USERS = 'Users'
export const ASSETS = 'Assets'
export const TASK = 'Task'
export const SETTINGS = 'Settings'
export const PROFILE = 'Profile'
export const REPORTS = 'Reports'
export const SORT_UP = <> <FontAwesomeIcon style={{ color: '#363166' }} size="xs" icon={faSortUp} fade /></>
export const SORT_DOWN = <> <FontAwesomeIcon style={{ color: '#363166' }} size="xs" icon={faSortDown} fade /></>
export const TOAST_TIMEOUT=2500
